import React from "react"
import styled from "styled-components"
import { Container } from "../global"
import appstore from "../../images/appstore.png"

const Header = () => {

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>Leaderboard</h1>
            <h1>
              <div style={{ fontSize: "30px" }}>
                A fun & friendly monthly competition
              </div>
            </h1>
            <h2>Available exclusively in the Day app.</h2>
            <h2>
              Win points every time you add to your count. The more you practice
              your daily routine, the more points you'll earn. See you at the
              top!
            </h2>
            <img src={appstore} alt="AppStore Logo" width="250px" />
          </HeaderTextGroup>
          <ImageWrapper>
            <img
              style={{ width: "80%" }}
              alt="Leaderboard"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/icons%2Fleaderboard-climb.png?alt=media&token=10164a34-aeb1-465d-9682-e2177a0259cf"
            />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
